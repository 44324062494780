import React, {useContext} from 'react';

import {Avatar, Button, Card, CardContent, Chip, Grid, Link, List, ListItem, ListItemAvatar, ListItemText, Typography} from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import {Create as CreateIcon, Update as UpdateIcon, CalendarToday as CalendarIcon, PeopleAlt as AssigneeCircleIcon} from '@material-ui/icons';

import * as PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import GitLabIcon from "../Assets/Icon/GitLabIcon";
import AccordionItem from "./AccordionItem";
import LanguageContext from "../LanguageContext";
import HighlightCode from "./HighlightCode";

AccordionIssue.propTypes = {
    issue: PropTypes.object.isRequired,
    project: PropTypes.object,
    id: PropTypes.number.isRequired
};

function useStyles() {
    return makeStyles(theme => ({
        button: {
            margin: theme.spacing(1),
        }
    }));
}

function AccordionIssue({id, issue, project}) {
    const classes = useStyles();
    const local = useContext(LanguageContext);
    // Project can be null only if the issue is from a private project
    if(!project){
        return null;
    }

    const convertDate = (dateStr, errStr = local.Component.AccordionIssue.missingDate) => {
        return dateStr
            ? local.Component.AccordionIssue.prefixDate + " " + new Date(dateStr).toLocaleString(local.Component.AccordionIssue.dateFormat, { timeZone: 'UTC' })
            : errStr;
    };

    const getAssignedMembersComponent = (members) => {
        if (members.length === 0){
            return local.Component.AccordionIssue.missingAssigneeText;
        }

        return (<AvatarGroup max={6}>
            {members.map((m, i) => {
                return (<Avatar alt={m.name} src={m.avatar_url} key={"avatar_" + i + "_" + m.name} imgProps={{title: m.name}}/>);
            })}
        </AvatarGroup>);
    };

    return (
        <AccordionItem id={"id_issue_"+issue.iid+"_"+id} key={"issue_"+id}
            title={ "Issue #" + issue.iid + ": " + issue.title}
            chip={
                <Chip avatar={
                    <Avatar variant="square" src={project.avatar_url}>
                        {project.name.charAt(0).toUpperCase()}
                    </Avatar>
                } size={"small"} label={project.name} style={{marginRight: "15px"}} clickable/>
            }
        >
            <Card elevation={0} style={{width: "100%"}}>
                <Typography component={"h2"} variant={"h5"}>{local.Component.AccordionIssue.issueHeaderText}</Typography>
                <ReactMarkdown source={issue.description} 
                    renderers={{
                        link: Link, 
                        code: HighlightCode, 
                        paragraph: ({ children }) => (<Typography component={"p"} variant={"body2"} paragraph style={{wordBreak: "break-word"}}>{children}</Typography>)
                    }}
                    skipHtml/>
                <Typography component={"h2"} variant={"h5"}>{local.Component.AccordionIssue.issueInfoText}</Typography>
                <CardContent>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={1} style={{textAlign: "center"}}>
                        <Grid item>
                            <Typography component={"h5"} variant={"subtitle1"}>
                                {project.tag_list.length
                                    ? local.Component.AccordionIssue.tagText
                                    : local.Component.AccordionIssue.missingTagText
                                }
                            </Typography>
                        </Grid>
                        {project.tag_list.map((tag, index) => { return <Grid item key={tag + "_" + index}><Chip color="primary" label={tag}/></Grid>;})}
                    </Grid>
                    <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={1} style={{textAlign: "center"}}>
                        <Grid item>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CreateIcon color="primary" className={classes.button}/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={convertDate(issue.created_at)}
                                        secondary={local.Component.AccordionIssue.creationDateText}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <UpdateIcon color="primary" className={classes.button}/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={convertDate(issue.updated_at)}
                                        secondary={local.Component.AccordionIssue.updateDateText}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CalendarIcon color="primary" className={classes.button}/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={convertDate(issue.due_date, local.Component.AccordionIssue.missingSchedulingText)}
                                        secondary={local.Component.AccordionIssue.schedulingText}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AssigneeCircleIcon color="primary" className={classes.button}/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={getAssignedMembersComponent(issue.assignees)}
                                        secondary={local.Component.AccordionIssue.assigneeText}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={1} style={{textAlign: "center"}}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                startIcon={<GitLabIcon/>}
                                aria-label="gitlab"
                                component={"a"}
                                href={issue.web_url}
                            >
                                {local.Component.AccordionIssue.Button.gitlab}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </AccordionItem>
    );
}

export default AccordionIssue;
