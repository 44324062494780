import React from 'react';

import * as PropTypes from "prop-types";
import hljs from 'highlight.js/lib';
// HighLight.js theme for code block
import "../../node_modules/highlight.js/styles/monokai-sublime.css";

HighlightCode.propTypes = {
    value: PropTypes.node.isRequired,
    language: PropTypes.string          // See https://github.com/highlightjs/highlight.js/blob/master/SUPPORTED_LANGUAGES.md
};

function HighlightCode({value, language}) {
    const codeRef = React.createRef();

    React.useEffect(() => {
        hljs.initHighlightingOnLoad();
        hljs.highlightBlock(codeRef.current);
    }, [codeRef]);

    return (
        <pre>
            <code ref={codeRef} className={language}>{value}</code>
        </pre>
    );
}

export default HighlightCode;