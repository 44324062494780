import RouterIcon from '@material-ui/icons/Router';
import WebIcon from '@material-ui/icons/Web';

import GitIcon from "../../Assets/Icon/GitIcon";
import GitLab from "../../Assets/Icon/GitLabIcon";
import DockerIcon from "../../Assets/Icon/DockerIcon";
import KubeIcon from "../../Assets/Icon/KubeIcon";
import ReactIcon from "../../Assets/Icon/ReactIcon";
import DjangoIcon from "../../Assets/Icon/DjangoIcon";
import LaravelIcon from "../../Assets/Icon/LaravelIcon";

import BackendPdf from "../../Assets/Pdf/Backend.pdf";
import DockerPdf from "../../Assets/Pdf/Docker.pdf";
import GitPdf from "../../Assets/Pdf/Git.pdf";
import GitlabPdf from "../../Assets/Pdf/Gitlab.pdf";
import KubePdf from "../../Assets/Pdf/Kubernetes.pdf";
import LaravelPdf from "../../Assets/Pdf/Laravel.pdf";
import ReactPdf from "../../Assets/Pdf/React.pdf";
import ReseauPdf from "../../Assets/Pdf/Reseaux.pdf";
import WebPdf from "../../Assets/Pdf/Web.pdf";

export const TRAINING_INFO = [
    {
        year: 2020,
        trainings: [
            {
                name: "Git",
                description: "Formation pour apprendre le principe et l'utilisation de cet indispensable outil de versionnage de code",
                peertube_link: "https://tube.nocturlab.fr/videos/watch/4ccdcd4b-06a1-425e-a74b-38762cb79862",
                pdf_link: GitPdf,
                logo: GitIcon
            },
            {
                name: "GitLab",
                description: "Formation pour apprendre les spécificités de la plateforme d'hébergement git, avec ses mécanismes avancés de gestion de projet et d'intégration continue",
                peertube_link: "https://tube.nocturlab.fr/videos/watch/95b7ae0e-ec4a-47d7-9394-a5999fdc3d26",
                pdf_link: GitlabPdf,
                logo: GitLab
            },
            {
                name: "Docker",
                description: "Formation pour maîtriser l'utilisation de ce système de conteneurs très utilisé au SIA, utile autant en développement qu'en infrastructure",
                peertube_link: "https://tube.nocturlab.fr/videos/watch/999ea464-828a-411c-971c-81723d5c5906",
                pdf_link: DockerPdf,
                logo: DockerIcon
            },
            {
                name: "Réseau",
                description: "Formation pour apprendre les bases du réseau avec le modèle OSI et les protocoles d'internet, mise en pratique via le logiciel GNS3",
                peertube_link: "https://tube.nocturlab.fr/videos/watch/fe88e37a-8dec-4f6b-86ef-6e2e2af62c80",
                pdf_link: ReseauPdf,
                logo: RouterIcon
            },
            {
                name: "Kubernetes",
                description: "Formation pour apprendre les bases de cet orchestrateur de conteneurs, la gestion du cycle de vie des conteneurs et applications pour permettre le scaling d'une application",
                peertube_link: "https://tube.nocturlab.fr/videos/watch/b85aa68a-a012-4e6e-ab94-adf1b8c9740a",
                pdf_link: KubePdf,
                logo: KubeIcon
            },
            {
                name: "Développement web, front-end - Généralités",
                description: "Formation pour découvrir les principes du web avec HTML et CSS",
                peertube_link: "https://tube.nocturlab.fr/videos/watch/68b41672-8d69-46f6-80bf-6ece406b52da",
                pdf_link: WebPdf,
                logo: WebIcon
            },
            {
                name: "Développement web, front-end - React",
                description: "Formation pour découvrir CSS, JavaScript et ReactJS",
                peertube_link: "https://tube.nocturlab.fr/videos/watch/47666888-8d0b-4ceb-b70b-3a596e12e7c9",
                pdf_link: ReactPdf,
                logo: ReactIcon
            },
            {
                name: "Django (Python)",
                description: "Formation pour apprendre les bases de Django et de Django REST",
                peertube_link: "https://tube.nocturlab.fr/videos/watch/61dabd47-9cc3-4e55-a95a-3bb5f6d27e53",
                pdf_link: BackendPdf,
                logo: DjangoIcon
            },
            {
                name: "Laravel (PHP)",
                description: "Formation pour apprendre les bases de Laravel",
                peertube_link: "https://tube.nocturlab.fr/videos/watch/392c94af-a287-4f5e-8dae-6bba34987960",
                pdf_link: LaravelPdf,
                logo: LaravelIcon
            }
        ]
    }
];
