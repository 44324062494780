import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

export default function DjangoIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg  version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 504.09 215.994" overflow="visible" style={{enableBackground: "new 0 0 504.09 215.994"}}>
                <path fill="#092E20" d="M504.09,187.994c0,15.464-12.536,28-28,28H28c-15.464,0-28-12.536-28-28V28C0,12.536,12.536,0,28,0h448.09
	c15.464,0,28,12.536,28,28V187.994z"/>
                <g>
                    <g>
                        <path fill="#FFFFFF" d="M86.945,33.919h23.872v110.496c-12.246,2.325-21.237,3.255-31.002,3.255
			c-29.142,0-44.333-13.174-44.333-38.443c0-24.336,16.122-40.147,41.078-40.147c3.875,0,6.82,0.311,10.386,1.239V33.919z
			M86.945,89.539c-2.79-0.929-5.115-1.239-8.06-1.239c-12.091,0-19.067,7.441-19.067,20.46c0,12.713,6.666,19.688,18.912,19.688
			c2.634,0,4.805-0.155,8.215-0.618V89.539z"/>
                        <path fill="#FFFFFF" d="M148.793,70.783v55.341c0,19.065-1.395,28.21-5.58,36.117c-3.876,7.596-8.992,12.399-19.532,17.67
			l-22.167-10.541c10.541-4.96,15.656-9.297,18.911-15.966c3.411-6.819,4.497-14.727,4.497-35.498V70.783H148.793z M124.922,34.046
			h23.871v24.493h-23.871V34.046z"/>
                        <path fill="#FFFFFF" d="M163.212,76.209c10.542-4.961,20.617-7.13,31.623-7.13c12.246,0,20.306,3.255,23.872,9.611
			c2.014,3.564,2.634,8.214,2.634,18.137v48.517c-10.697,1.552-24.182,2.636-34.102,2.636c-19.996,0-28.988-6.977-28.988-22.476
			c0-16.744,11.936-24.493,41.234-26.975v-5.271c0-4.339-2.17-5.888-8.216-5.888c-8.835,0-18.756,2.479-28.058,7.285V76.209z
			M200.57,114.187c-15.812,1.552-20.927,4.031-20.927,10.231c0,4.65,2.946,6.821,9.456,6.821c3.566,0,6.82-0.311,11.471-1.084
			V114.187z"/>
                        <path fill="#FFFFFF" d="M232.968,74.505c14.105-3.722,25.731-5.426,37.512-5.426c12.246,0,21.082,2.788,26.354,8.216
			c4.96,5.113,6.509,10.693,6.509,22.632v46.813h-23.871v-45.884c0-9.145-3.1-12.557-11.625-12.557c-3.255,0-6.2,0.311-11.007,1.706
			v56.734h-23.871V74.505z"/>
                        <path fill="#FFFFFF" d="M312.623,159.761c8.372,4.339,16.742,6.354,25.577,6.354c15.655,0,22.321-6.354,22.321-21.546
			c0-0.154,0-0.31,0-0.467c-4.65,2.326-9.301,3.257-15.5,3.257c-20.927,0-34.26-13.797-34.26-35.652
			c0-27.128,19.688-42.473,54.564-42.473c10.232,0,19.688,1.084,31.159,3.407l-8.174,17.222c-6.356-1.241-0.509-0.167-5.312-0.632
			v2.48l0.309,10.074l0.154,13.022c0.155,3.253,0.155,6.51,0.311,9.764c0,2.945,0,4.342,0,6.512c0,20.462-1.705,30.073-6.82,37.977
			c-7.441,11.627-20.307,17.362-38.598,17.362c-9.301,0-17.36-1.396-25.732-4.651V159.761z M360.057,88.455c-0.31,0-0.619,0-0.774,0
			h-1.706c-4.649-0.155-10.074,1.084-13.796,3.409c-5.734,3.257-8.681,9.146-8.681,17.518c0,11.937,5.892,18.756,16.432,18.756
			c3.255,0,5.891-0.62,8.99-1.55v-1.705v-6.51c0-2.79-0.154-5.892-0.154-9.146l-0.154-11.006l-0.156-7.905V88.455z"/>
                        <path fill="#FFFFFF" d="M433.543,68.77c23.871,0,38.443,15.037,38.443,39.371c0,24.957-15.19,40.613-39.373,40.613
			c-23.873,0-38.599-15.036-38.599-39.216C394.015,84.424,409.207,68.77,433.543,68.77z M433.076,129.533
			c9.147,0,14.573-7.596,14.573-20.773c0-13.019-5.271-20.771-14.415-20.771c-9.457,0-14.884,7.598-14.884,20.771
			C418.351,121.938,423.777,129.533,433.076,129.533z"/>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    );
}
