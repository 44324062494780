import React from "react";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BugReportIcon from '@material-ui/icons/BugReport';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import SettingsIcon from '@material-ui/icons/Settings';
import StarsIcon from '@material-ui/icons/Stars';

export default {
    GUEST: {id: 10, languageKey: "GUEST", getIcon: () => <AccountCircleIcon />, color: "secondary"},
    REPORTER: {id: 20, languageKey: "REPORTER", getIcon: () => <BugReportIcon />, color: "secondary"},
    DEVELOPER: {id: 30, languageKey: "DEVELOPER", getIcon: () => <DeveloperModeIcon />, color: "primary"},
    MAINTAINER: {id: 40, languageKey: "MAINTAINER", getIcon: () => <SettingsIcon />, color: "primary"},
    OWNER: {id: 50, languageKey: "OWNER", getIcon: () => <StarsIcon />, color: "primary"},
};