import React, {useContext} from 'react';
import {Avatar, Checkbox, Chip, FormControl, Grid, Input, InputAdornment, InputLabel,ListItemText, MenuItem, Select, Typography} from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Search as SearchIcon, FilterList as FilterListIcon, Sort as SortIcon} from '@material-ui/icons';

import AccordionItem from "../Component/AccordionItem";
import AccordionIssue from "../Component/AccordionIssue";

import Logo_SIA from "../Assets/Image/sia_color.png";
import LanguageContext from "../LanguageContext";
import config from "../config.json";

import {getDefaultErrorMessage} from "../Request/requestErrors";
import {getAllIssuesByGroup} from "../Request/GitLab/issue";
import {getAllProjectByRange} from "../Request/GitLab/project";

function useStyles() {
    return makeStyles(theme => ({
        root: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        container: {
            display: 'flex',
        },
        paper: {
            margin: theme.spacing(1),
        },
        desc: {
            padding_top: theme.spacing(10),
        },
        deposit: {
            alignSelf: 'baseline center',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: "25vw"
        }
    }));
}


function Home ({errorHandler}) {
    const classes = useStyles();
    const local = useContext(LanguageContext);
    const availableFilter = ["0", "1", "2"];

    const [loading, setLoading] = React.useState(false);
    const [completeIssuesList, setCompleteIssuesList] = React.useState([]);
    const [issuesList, setIssuesList] = React.useState([]);
    const [activeFilter, setActiveFilter] = React.useState(availableFilter[0]);

    const [completeProjectList, setCompleteProjectList] = React.useState([]);
    const [projectList, setProjectList] = React.useState([]);

    const handleFilter = (event) => {
        let filterChosen = event.target.value;
        setActiveFilter(filterChosen);
        switch (filterChosen){
        case "1":
            //Sort by create dsc
            setIssuesList([...completeIssuesList].sort((i1, i2) => {
                return new Date(i2.created_at) - new Date(i1.created_at);
            }));
            setCompleteIssuesList(completeIssuesList.sort((i1, i2) => {
                return new Date(i2.created_at) - new Date(i1.created_at);
            }));
            break;
        case "2":
        default:
            //Sort by update dsc
            setIssuesList([...completeIssuesList].sort((i1, i2) => {
                return new Date(i2.updated_at) - new Date(i1.updated_at);
            }));
            setCompleteIssuesList(completeIssuesList.sort((i1, i2) => {
                return new Date(i2.updated_at) - new Date(i1.updated_at);
            }));
            break;
        }
    };

    const handleSearch = (event) => {
        let name = (event.target.value)
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

        setIssuesList(completeIssuesList.filter(issue => {
            // Do not override project filter
            if(getProjectFromState(issue.project_id) === null){
                return false;
            }

            return (issue.title
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .search(name) !== -1);
        }));
    };

    const handleProjectFilter = (event) => {
        const projectSelected = event.target.value;
        setProjectList(projectSelected);

        setIssuesList(completeIssuesList.filter(issue => {
            for(let i=0; i < projectSelected.length; i++){
                if(issue.project_id === projectSelected[i].id){
                    return true;
                }
            }
            return false;
        }));
    };

    const getProjectFromState = (id) => {
        let project = null;
        for(let i=0; i < projectList.length && project === null; i++){
            if(projectList[i].id === id){
                project = projectList[i];
            }
        }
        return project;
    };

    React.useEffect((local) => {
        setLoading(true);
        getAllIssuesByGroup(config.SIA_GROUP_ID, 1, 20).then( res => {
            setIssuesList(res.data);
            setCompleteIssuesList(res.data);

            if(res.data.length > 0){
                //Get project ID min/max to retrieve info on them
                let minId = res.data[0].project_id;
                let maxId = res.data[0].project_id;

                for(let i=0; i < res.data.length; i++){
                    const issue = res.data[i];
                    if(issue.project_id < minId){
                        minId = issue.project_id;
                    } else if(issue.project_id > maxId){
                        maxId = issue.project_id;
                    }
                }

                getAllProjectByRange(minId, maxId).then( resProject => {
                    const filteredList = resProject.data.filter( project => {
                        for (let i=0; i<res.data.length; i++){
                            if(res.data[i].project_id === project.id){
                                return true;
                            }
                        }
                        return false;
                    });

                    setCompleteProjectList(filteredList);
                    setProjectList(filteredList);
                    setLoading(false);
                }).catch( err => {
                    errorHandler(getDefaultErrorMessage(err, local));
                });
            } else {
                setLoading(false);
            }

        }).catch( err => {
            errorHandler(getDefaultErrorMessage(err, local));
        });
    }, [errorHandler]);

    return (
        <div className={classes.root} style={{margin: 20,}}>
            <div className={classes.container}>
                <img src={Logo_SIA} alt="Logo SIA" width="100" height="100" />
                <div title="Description SIA" className={classes.desc}>
                    <Typography variant="h5" color="textPrimary" align="center">
                        {local.View.Home.title}
                    </Typography>
                    <Typography variant="body1" color="textPrimary" align="center" style={{margin: 10}}>
                        {local.View.Home.subtitle}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        {local.View.Home.captionText}
                    </Typography>
                </div>
                <br/>
                <Grid container display="row" justify="space-between" alignItems="center">
                    <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="issue-search-label">{local.View.Home.searchPlaceholder}</InputLabel>
                            <Input id="issue-search"
                                type="search"
                                startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                                onChange={handleSearch}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="multiple-filter-project-label">{local.View.Home.filterProjectPlaceholder}</InputLabel>
                            <Select id="multiple-filter-project" displayEmpty multiple value={projectList} onChange={handleProjectFilter}
                                input={<Input id={"multiple-filter-project-input"} startAdornment={<InputAdornment position="start"><FilterListIcon/></InputAdornment>} />}
                                renderValue={selected => { return selected.length + local.View.Home.filterProjectResume;}}
                                labelId="multiple-filter-project-label"
                            >
                                {completeProjectList.map((project) => (
                                    <MenuItem key={project.name} value={project}>
                                        <Checkbox checked={projectList.indexOf(project) > -1} />
                                        <Avatar variant="square" src={project.avatar_url}>{project.name.charAt(0).toUpperCase()}</Avatar>
                                        <ListItemText primary={project.name} style={{marginLeft: "15px"}}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="issue-select-filter-label">{local.View.Home.filterPlaceholder}</InputLabel>
                            <Select id="issue-select-filter"
                                fullWidth
                                input={<Input startAdornment={<InputAdornment position="start"><SortIcon/></InputAdornment>}/>}
                                value={activeFilter}
                                onChange={handleFilter}
                            >
                                {availableFilter.map((f,i) => {
                                    return (<MenuItem value={""+i} key={"filter_"+i}>{local.View.Home.filterNames[i]}</MenuItem>);
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <br/>
                <div>
                    {loading
                        ? <React.Fragment>
                            <AccordionItem id={"Loading_1"} title={"Loading"} chip={<Chip label="Load"/>} loading/>
                            <AccordionItem id={"Loading_2"} title={"Loading"} chip={<Chip label="Load"/>} loading/>
                            <AccordionItem id={"Loading_3"} title={"Loading"} chip={<Chip label="Load"/>} loading/>
                            <AccordionItem id={"Loading_4"} title={"Loading"} chip={<Chip label="Load"/>} loading/>
                            <AccordionItem id={"Loading_5"} title={"Loading"} chip={<Chip label="Load"/>} loading/>
                        </React.Fragment>
                        : ((issuesList.length > 0 && projectList.length > 0)
                            ? issuesList.map((i, id) => {return (<AccordionIssue id={id} project={getProjectFromState(i.project_id)} issue={i} key={"issue_accordion_"+id}/>);})
                            : <Typography variant={"caption"}>{local.View.Home.missingIssueText}</Typography>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Home;
