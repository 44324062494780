import React, {useContext} from 'react';
import * as PropTypes from "prop-types";
import { Avatar, Button, Grid, Paper, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from '@material-ui/core/styles';

import getChipElement from "../Utils/UserRole";
import LanguageContext from "../LanguageContext";

UserCard.propTypes = {
    avatar: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    web: PropTypes.string.isRequired,
    access_level: PropTypes.number.isRequired,
    loading: PropTypes.bool,
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 400,
    },
    large: {
        width: "10vh",
        height: "10vh",
        maxWidth: theme.spacing(10),
        maxHeight: theme.spacing(10),
    },
    loading: {
        margin: 'auto',
    },
    loadingChip: {
        margin: 'auto',
        borderRadius: 16
    }
})
);

export default function UserCard({avatar, name, username, web, access_level, loading}) {
    const classes = useStyles();
    const local = useContext(LanguageContext);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={4}>
                <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                    <Grid item xs={2}>
                        {loading ? <Skeleton animation="wave" variant="circle" className={classes.large} />
                            : <Avatar alt={"Avatar of @" + username} src={avatar} className={classes.large}/>
                        }
                    </Grid>
                    <Grid item xs={10} container>
                        <Grid item xs container direction="column" spacing={2}>
                            {loading ? <React.Fragment>
                                <Grid item xs>
                                    <Skeleton variant="text" animation="wave" className={classes.loading} />
                                    <Skeleton variant="text" animation="wave" className={classes.loading} width={'60%'}/>
                                    <Skeleton variant="text" animation="wave" className={classes.loadingChip} width={'50%'} height={"8vh"}/>
                                </Grid>
                                <Grid item>
                                    <Skeleton variant="rect" animation="wave" className={classes.loading} width={"80%"} height={"5vh"}/>
                                </Grid>
                            </React.Fragment>
                                : <React.Fragment>
                                    <Grid item xs>
                                        <Typography gutterBottom variant="subtitle1">
                                            {name}
                                        </Typography>
                                        <Typography gutterBottom variant="subtitle1" color="textSecondary">
                                            {'@' + username}
                                        </Typography>
                                        {getChipElement(access_level)}
                                    </Grid>
                                    <Grid item>
                                        <Button size="small" component={"a"} href={web}>{local.Component.UserCard.seeProfile}</Button>
                                    </Grid>
                                </React.Fragment>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}
