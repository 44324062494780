import React, {useState, useEffect, useContext} from 'react';
import * as PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Container, Divider, Grid, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import UserCard from "../Component/UserCard";
import {getDefaultErrorMessage} from "../Request/requestErrors";
import {getAllGroupMembers} from "../Request/GitLab/member";
import config from "../config";
import LanguageContext from "../LanguageContext";

Members.propTypes = {
    errorHandler: PropTypes.func.isRequired,
};

function useStyles() {
    return makeStyles(theme => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        headerContent: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(8, 0, 6),
        }
    }));
}

function Members ({errorHandler}) {

    const [loaded, setLoaded] = useState(false);
    const [memberList, setMemberList] = useState([]);

    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const classes = useStyles();
    const local = useContext(LanguageContext);

    useEffect((local) => {
        setLoaded(false);
        errorHandler("");
        getAllGroupMembers(config.GESTION_SUBGROUP_ID, currentPage, 24).then( res => {
            //Little trick here, we doesn't want to show the @SIAbot member to the homepage member list
            setMemberList(res.data.filter((e) => {return (e.id !== config.SIA_ACCOUNT_ID);}));
            setTotalPage(Number(res.pagination["total-page"]));
            setCurrentPage(Number(res.pagination.page));
            setLoaded(true);
        }).catch( err => {
            errorHandler(getDefaultErrorMessage(err, local));
        });
    }, [currentPage, errorHandler]);

    const changePageHandler = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <React.Fragment>
            <br/>
            <Container maxWidth="md" className={classes.headerContent}>
                <Typography variant="h3" component="h2" align="center" gutterBottom>
                    {local.View.Members.title}
                </Typography>
                <Typography variant="subtitle1" component="p" align="center" paragraph>
                    {local.View.Members.subtitle}
                </Typography>
            </Container>
            <Grid container className={classes.root}
                spacing={2} direction="row" justify="center" alignItems="center">
                {loaded ? memberList.map((element, index) => {
                    return(<Grid item xs={10} sm={6} lg={4} key={'user_' + index}>
                        <UserCard avatar={element.avatar_url} name={element.name} username={element.username}
                            web={element.web_url} access_level={element.access_level}/>
                    </Grid>);
                }) : [0, 1, 2, 3, 4, 5].map((element) => {
                    return(<Grid item xs={10} sm={6} lg={4} key={'user_' + element}>
                        <UserCard avatar={""} name={""} username={""}
                            web={""} access_level={0} loading />
                    </Grid>);
                })
                }
            </Grid>
            {(totalPage > 1)
                ? <React.Fragment>
                    <br/>
                    <Divider/>
                    <br/>
                    <Grid container className={classes.root} justify="center" alignItems="center">
                        <Pagination count={totalPage} page={currentPage} onChange={changePageHandler} variant="outlined" color="primary"/>
                    </Grid>
                </React.Fragment>
                : null
            }
        </React.Fragment>
    );
}

export default Members;
