export const local = {
    Component: {
        Footer: {
            "seeSection": "Voir sur"
        },
        ProjectCard: {
            "hours": "heures",
            "days": "jours",
            "openIssue": " tickets ouverts",
            "createIssue": "Créer un ticket",
            "lastActivity": "Depuis la dernière activité",
            "seeProject": "Voir le projet"
        },
        RoleType: {
            GUEST: "Invité",
            REPORTER: "Rapporteur",
            DEVELOPER: "Développeur",
            MAINTAINER: "Mainteneur",
            OWNER: "Propriétaire"
        },
        SnackBarComponent: {
            "undoButton": "Annuler"
        },
        UserCard: {
            "seeProfile": "Voir le profil"
        },
        AccordionIssue: {
            "issueHeaderText": "Détails de l'issue",
            "issueInfoText": "Informations additionnelles",
            "creationDateText": "Date de création",
            "updateDateText": "Date de dernière mise à jour",
            "dateFormat": "fr-FR",
            "prefixDate": "Le",
            "missingDate": "Aucune date de disponible",
            "schedulingText": "Date de fin prévue",
            "missingSchedulingText": "Non définie",
            "assigneeText": "Personnes assignées",
            "missingAssigneeText": "En recherche de personnes",
            "tagText": "Mot-clefs :",
            "missingTagText": "Aucun mot-clef n'est associé à ce projet !",
            Button: {
                "gitlab": "Voir sur GitLab"
            }
        },
        AppDrawer: {
            translateButton: "Change to English"
        },
        TrainingCard: {
            Tooltip: {
                "video": "Voir l'enregistrement",
                "pdf": "Aller au support de formation"
            },
            IconButton: {
                "video": "Voir l'enregistrement",
                "pdf": "Aller au support de formation",
                "expand": "Voir le détail"
            }
        }
    },
    Request: {
        error: {
            BadRequest: "Une erreur est survenue, veuillez recharger la page !",
            ErrorLogin: "Le token d'authentification a expiré, veuillez contacter un membre du SIA !",
            ErrorForbidden: "La ressource que vous tentez d'accèder vous est interdite !",
            ErrorNotFound: "Cette ressource est introuvable !",
            Default: "Une erreur est survenue, veuillez contacter un membre du SIA !",
        }
    },
    Route: {
        "/": "Accueil",
        "/team": "Equipes",
        "/projects": "Projets",
        "/training": "Formations",
        "/about": "A propos"
    },
    View: {
        About: {
            "title": "A propos du SIA",
            "subtitle": "Vous trouverez ici une courte FAQ, ainsi que diverses informations sur nous",
            Accordion: {
                "intro": {
                    "title": "Qu’est ce que le SIA ?",
                    "content": [
                        "Le Service Informatique Associatif (SIA) est une équipe qui a été créée dans le but de proposer des services informatiques aux associations et à leurs membres. Notre champ d’action est large : nous pouvons effectuer la mise en place, l’hébergement ou encore le développement d’outils informatiques.\n",
                        "Nous souhaitons mettre en place des outils utiles à la Vie Associative du campus. Des outils qui faciliteront les interactions entre les associations et qui simplifieront leur gestion. Nous pensons qu’il est plus efficace de mutualiser les ressources afin de réaliser des outils plus adaptés et plus pérennes en centralisant et répertoriant les projets informatiques réalisés pour la VA.\n",
                        "Le SIA a également pour mission d’éduquer aux concepts de vie privée et de sécurité des données ainsi que de proposer des outils alternatifs, libres ou open-source et efficaces.\n"
                    ]
                },
                "project": {
                    "title": "Quels sont les projets du SIA ?",
                    "content": [
                        "Les projets du SIA sont de natures différentes, nous avons 3 principaux pôles :",
                        "Infrastructure : c’est la gestion des serveurs. On y fait du K8s, de la virtualisation, du réseau, du SysAdmin.",
                        "Développement : c'est le pôle principal du SIA. Les développeurs travaillent sur des projets qui sont réellement demandés et utilisés par la suite. On utilise surtout du ReactJS, du Django (Python) et du Laravel (PHP). Appartenir à ce pôle peut être une opportunité pour découvrir le développement de logiciel d'une manière différente de ce qui peut être appris à l'INSA",
                        "PPT : le Pôle Pas Technique, on y parle de protection des données, de comment on restreint l’accès aux infos qu’on a et on y gère la communication entre le SIA et les assos. Le but est d’avoir des gens motivés pour qu’on fasse des applications qui répondent aux besoins des assos en respectant le cadre légal et la vie privée de nos utilisateurs."
                    ]
                },
                "contribute": {
                    "title": "Qui peut contribuer au SIA ?",
                    "content": [
                        "Ça te parle ? L’idée te plaît ? Rejoins-nous !",
                        "Aujourd’hui le SIA est composé d’une dizaine de personnes, qui s’investissent dans les différents projets. Nous recherchons des personnes qui seraient intéressées pour nous aider. Tu n’as pas forcément besoin d’avoir des compétences techniques. Nous proposons des formations pour te faire découvrir nos projets et les technologies que nous utilisons si ça t’intéresse.",
                        "Pas envie de faire du code ? Nous avons aussi des projets non-techniques et nous cherchons des testeurs pour nos applications.",
                        "Au SIA tu peux participer sur un projet ou rejoindre l’équipe et travailler avec nous toute l’année selon tes disponibilités."
                    ]
                },
                "workload": {
                    "title": "La charge de travail",
                    "content": [
                        "La charge de travail au SIA c’est toi qui la définis !",
                        "Tu as le choix de travailler sur un ou plusieurs projets, à différents moments de l’année ou sur l’année entière !"
                    ]
                },
                "contact": {
                    "title": "Comment nous contacter ?",
                    "content": [
                        "Prends contact avec nous sur le discord VA tous les mercredis à partir de 18h ! Tu pourras rencontrer des membres du SIA et leur poser des questions !\n",
                        "Si tu n'aimes pas discord, tu peux toujours nous contacter par mail ou contribuer directement à un de nos projets."
                    ],
                    Button: {
                        "discord": "Discord de la VA",
                        "mail": "Envoyer un mail",
                        "gitlab": "Voir sur GitLab"
                    }
                },
                "history": {
                    "title": "Histoire du SIA",
                    Timeline: [
                        {"left": "2018", "right": "Benoît Renault lance l'idée du SIA"},
                        {"left": "Eté 2019", "right": "Les préparations pour la formation de l'embryon du SIA sont finies"},
                        {"left": "Rentrée 2019", "right": "Recrutement d'une dizaine de membres non Orga'IF au sein du SIA, Week-end de formation et validation de l'équipe SIA par le CA du BdE"},
                        {"left": "Fin 2019", "right": "Election du responsable SIA et formation du bureau du SIA (4 personnes issues de la DSI, du CA du BdE, du CVA et du SIA)"},
                        {"left": "Début 2020", "right": "Lancement du site de l'équipe, de nouveaux projets et d'un projet en collaboration avec des externes"},
                        {"left": "Juin 2020", "right": "Election du nouveau responsable SIA et première passation"}
                    ]
                },
            }
        },
        Home: {
            "title": "Le SIA (Service Informatique Associatif) est une équipe du BdE dont le but est de\n" +
                "fournir des services aux associations INSA Lyon.",
            "subtitle": "Si vous souhaitez obtenir des informations sur le SIA, rendez-vous à la section \"A propos\" !",
            "captionText": "Ci-dessous, vous trouverez les tâches ouverte aux contributions extérieures au SIA. N'hésitez pas à les regardez si vous souhaitez nous venir en aide !",
            "missingIssueText": "Aucune issue n'est ouverte à la communauté pour le moment.",
            "searchPlaceholder": "Chercher par nom",
            "filterPlaceholder": "Trier",
            "filterProjectPlaceholder": "Filtrer par projet",
            "filterProjectResume": " projets sélectionnés",
            "filterNames": ["Aucun", "Par la plus récente créée", "Par la plus récenté active"]
        },
        Members: {
            "title": "Liste des membres de l'équipe du SIA",
            "subtitle": "Vous trouverez ici la liste des membres actifs sur GitLab",
        },
        Projects: {
            "title": "Liste des projets de l'équipe du SIA",
            "subtitle": "Vous trouverez ici la liste des projets présents sur GitLab. Les projets ci-présents sont ouverts\n" +
                "aux contributions, n'hésitez pas à y jeter un coup d'oeil, voir même à un participer !\n",
        },
        Training: {
            "title": "Liste des formations",
            "subtitle": "Vous trouverez ici les formations délivrées durant les week-ends du SIA !\n",
        },
    }
};