export const local = {
    Component: {
        Footer: {
            "seeSection": "See on"
        },
        ProjectCard: {
            "hours": "hours",
            "days": "days",
            "openIssue": " Issues opened",
            "createIssue": "Create an issue",
            "lastActivity": "Since the last activity",
            "seeProject": "See project"
        },
        RoleType: {
            GUEST: "Guest",
            REPORTER: "Reporter",
            DEVELOPER: "Developer",
            MAINTAINER: "Maintainer",
            OWNER: "Owner"
        },
        SnackBarComponent: {
            "undoButton": "Undo"
        },
        UserCard: {
            "seeProfile": "See profile"
        },
        AccordionIssue: {
            "issueHeaderText": "Issue details",
            "issueInfoText": "Additional information",
            "creationDateText": "Creation date",
            "updateDateText": "Last update date",
            "dateFormat": "en-GB",
            "prefixDate": "At",
            "missingDate": "No date available",
            "schedulingText": "Ending date scheduled",
            "missingSchedulingText": "None",
            "assigneeText": "Assigned people",
            "missingAssigneeText": "In search of people",
            "missingIssueText": "There is no issue open that can be completed by the community.",
            "tagText": "Key words:",
            "missingTagText": "No key words is associated to this project!",
            Button: {
                "gitlab": "See on GitLab"
            }
        },
        AppDrawer: {
            translateButton: "Passer au français"
        },
        TrainingCard: {
            Tooltip: {
                "video": "See the recording",
                "pdf": "See the training support"
            },
            IconButton: {
                "video": "See the recording",
                "pdf": "See the training support",
                "expand": "See details"
            }
        }
    },
    Request: {
        error: {
            BadRequest: "An error happened, please refresh the page!",
            ErrorLogin: "The authentication token has expired, please contact a SIA member!",
            ErrorForbidden: "You are trying to access to a forbidden resource!",
            ErrorNotFound: "This resource cannot be found!",
            Default: "An error happened, please contact a SIA member!",
        }
    },
    Route: {
        "/": "Home",
        "/team": "Team",
        "/projects": "Projects",
        "/training": "Training",
        "/about": "About SIA"
    },
    View: {
        About: {
            "title": "About the SIA",
            "subtitle": "You can find here a brief FAQ, as well as various information on us",
            Accordion: {
                "intro": {
                    "title": "What is the SIA ?",
                    "content": [
                        "The Service Informatique Associatif (SIA) is a team which has been created with the goal to provide developement services to associations and their members. Our action field is wide: we can deploy, host or even develop software.\n",
                        "We wants to set up useful tools for the associative life of our campus. Theses tools will facilitate the interaction between the different associations of the campus and will simplify their management. In order to build more adapted and sustainable tools, we believe it is more efficient to mutualize the resources and projects created by the associative life.\n",
                        " One of the missions of the SIA is also to educate students on privacy and data security concepts. It aims to propose alternative tools that are free or open source and efficient.\n"
                    ]
                },
                "project": {
                    "title": "What can be a SIA project ?",
                    "content": [
                        "SIA project can be of different kind, we have 3 main departments :",
                        "Infrastructure : it is the management of servers. We touch to K8s, virtualization, network, and SysAdmin.",
                        "Software development : it is the main part of the SIA. Developers work on projects that are really needed and used afterward. We use principally ReactJS, Django (Python) and Laravel (PHP). Belong to this departement can be the opportunity to discover software development in a different way from what can be taught at the INSA.",
                        "PPT : the Non Technical Department, we talk about data protection, about how we restrict access to information that we have, and how we manage the communication between the SIA and the associations. The goal is to have motivated people in order to create apps that respond to the needs of associations, while respecting the law and the privacy of our users."
                    ]
                },
                "contribute": {
                    "title": "Who can contribute to the SIA ?",
                    "content": [
                        "Are inspired by all of this? Do you like the idea? Join us !",
                        "Nowadays, the SIA is composed of a dozen of people, who are invested in many different kind of projects. We search people that are interessed to help us. You don't necessarily need to have technical skills. We propose different kinds of formations to allow you to discover our projects and the techniques that we use, if you are interested.",
                        "You don't wants to code? We also have non-technical projects and we are looking for people to test our apps.",
                        "In the SIA, you can participate on a project or join the team and work with us the whole year according to your availability."
                    ]
                },
                "workload": {
                    "title": "What is the workload ?",
                    "content": [
                        "The workload with the SIA is defined by you, and only you!",
                        "You have the choice to work on one or many projects, at different time of the year or during the whole year!"
                    ]
                },
                "contact": {
                    "title": "How to contact us ?",
                    "content": [
                        "You can contact us on the associative life discord server every wednesday (from 6pm)! You will be able to meet members of the SIA team and ask them questions there!\n",
                        "If you dislike discord, you can still contact us by mail, or contribute directly to one of our projects."
                    ],
                    Button: {
                        "discord": "Discord of VA",
                        "mail": "Send a mail",
                        "gitlab": "See on GitLab"
                    }
                },
                "history": {
                    "title": "History of SIA",
                    Timeline: [
                        {"left": "2018", "right": "Benoît Renault propose the idea of the SIA"},
                        {"left": "Summer 2019", "right": "The preparation to form the base of the SIA are over"},
                        {"left": "Start of winter semester 2019", "right": "Recruitment of a dozen of members (non-affiliated to Orga'IF team) into the SIA, Formation Week-end and validation of the SIA team by the CA of the BdE"},
                        {"left": "End 2019", "right": "Election of a person in charge of the SIA and formation of the SIA office (4 peoples from the DSI, the CA of BdE, the CVA and the SIA)"},
                        {"left": "Start 2020", "right": "Launch of the website, of new projects and of a project in collaboration with external contributors"},
                        {"left": "June 2020", "right": "Election of the new person in charge of the SIA and first handover of power"}
                    ]
                },
            }
        },
        Home: {
            "title": "The SIA (Service Informatique Associatif) is a team of the INSA Lyon BdE which has the vocation\n" +
                "to provide services to the INSA Lyon associations.",
            "subtitle": "If you want to get information about the SIA, please check the \"About SIA\" section!",
            "captionText": "You will find below task that are open to external contribution. Don't hesitate to look at them if you want to help us!",
            "searchPlaceholder": "Search by name",
            "filterPlaceholder": "Sort",
            "filterProjectPlaceholder": "Filter by project",
            "filterProjectResume": " selected project",
            "filterNames": ["None", "Last created", "Last updated"]
        },
        Members: {
            "title": "List of the members of the SIA's team",
            "subtitle": "You can find here the list of our active members on GitLab",
        },
        Projects: {
            "title": "List of projects conducted by the SIA's team",
            "subtitle": "You can find here a list of projects available on GitLab. Theses projects are open\n" +
                "to contributions, don't hesitate to look at them, or even participate on one!\n",
        },
        Training: {
            "title": "List of training (Only in French)",
            "subtitle": "You can find here a list of trainings hold during the SIA week-ends!\n",
        },
    }
};
