import React, {useContext} from 'react';
import * as PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, Tooltip, Typography} from "@material-ui/core";
import MovieIcon from '@material-ui/icons/Movie';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import LanguageContext from "../LanguageContext";

TrainingCard.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    video: PropTypes.string.isRequired,
    pdf: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired
};

function useStyles() {
    return makeStyles(() => ({
        root: {
            maxWidth: 345
        }
    }));
}

function TrainingCard ({title, text, video, pdf, icon}) {
    const classes = useStyles();
    const local = useContext(LanguageContext);

    return (
        <Card classes={classes.root}>
            <CardHeader title={title}/>
            <CardMedia style={{height: "25%", width:"25%", margin: "auto"}} component={icon} title={"Icon "+title}/>
            <CardContent>
                <Typography paragraph>{text}</Typography>
            </CardContent>
            <CardActions disableSpacing>
                <Tooltip title={local.Component.TrainingCard.Tooltip.video}>
                    <IconButton component={"a"} href={video} rel="noopener" target="_blank" aria-label={local.Component.TrainingCard.IconButton.video}>
                        <MovieIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={local.Component.TrainingCard.Tooltip.pdf}>
                    <IconButton component={"a"} href={pdf} rel="noopener" target="_blank" aria-label={local.Component.TrainingCard.IconButton.pdf}>
                        <PictureAsPdfIcon />
                    </IconButton>
                </Tooltip>
            </CardActions>
        </Card>
    );
}

export default TrainingCard;
