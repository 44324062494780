import React from "react";
import PeopleIcon from '@material-ui/icons/People';
import CodeIcon from '@material-ui/icons/Code';
import HomeIcon from '@material-ui/icons/Home';
import HelpIcon from '@material-ui/icons/Help';
import TrainingIcon from '@material-ui/icons/School';

import Home from "../View/Home";
import Projects from "../View/Projects";
import Members from "../View/Members";
import Training from "../View/Training";
import About from "../View/About";

export const route = [[
    {
        render: Home,
        path: "/",
        icon: <HomeIcon/>
    },
],[
    {
        render: Members,
        path: "/team",
        icon: <PeopleIcon/>
    },
    {
        render: Projects,
        path: "/projects",
        icon: <CodeIcon/>
    }
],[
    {
        render: Training,
        path: "/training",
        icon: <TrainingIcon/>
    },
    {
        render: About,
        path: "/about",
        icon: <HelpIcon/>
    },
]];