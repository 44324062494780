import axios from "axios";
import { rematchError } from '../requestErrors';
import config from "../../config";

const client = axios.create({
    baseURL: config.ENV_DEV,
    //Allowing the use of GitLab personal Token to get more info
    headers: (process.env.REACT_APP_TOKEN_GITLAB) ? {
        common: {
            'Private-Token': process.env.REACT_APP_TOKEN_GITLAB,
            'Authorization': 'Bearer ' + process.env.REACT_APP_TOKEN_GITLAB
        }
    } : {}
});

export const getAllProjectByGroup = (id = config.SIA_GROUP_ID, page = 1, per_page = 100) => {
    return client.get('groups/' + id + '/projects', {
        params: {
            page: page,
            per_page: per_page,
            archived: false,
            visibility: "public",
            include_subgroups: true
        }
    }).then(response => {
        return {
            data: response.data,
            pagination: {
                'next-page': response.headers["x-next-page"],
                'previous-page': response.headers["x-prev-page"],
                'page': response.headers["x-page"],
                'total': response.headers["x-total"],
                'total-page': response.headers["x-total-pages"],
            }
        };
    }).catch(rematchError);
};

export const getAllProjectByRange = (beginId, endID, groupId = config.SIA_GROUP_ID, page = 1, per_page = 100) => {
    return client.get('groups/' + groupId + '/projects', {
        params: {
            page: page,
            per_page: per_page,
            archived: false,
            visibility: "public",
            include_subgroups: true,
            id_before: Number(endID) + 1,       //Allow to get every project that is inside the interval with min/max id included
            id_after: Number(beginId) - 1
        }
    }).then(response => {
        return {
            data: response.data,
            pagination: {
                'next-page': response.headers["x-next-page"],
                'previous-page': response.headers["x-prev-page"],
                'page': response.headers["x-page"],
                'total': response.headers["x-total"],
                'total-page': response.headers["x-total-pages"],
            }
        };
    }).catch(rematchError);
};

export const getProjectByID = (id) => {
    return client.get('projets/' + id + '/')
        .then(response => {
            return response.data;
        }).catch(rematchError);
};

export const getAllSubgroups = (id = config.SIA_GROUP_ID) => {
    return client.get('groups/' + id + '/subgroups/')
        .then(response => {
            return response.data;
        }).catch(rematchError);
};

export const getProjectStats = (id) => {
    return client.get('project/' + id + '/issues_statistics/')
        .then(response => {
            return response.data;
        }).catch(rematchError);
};
