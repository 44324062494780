import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

export default function DockerIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30">
                <path
                    style={{StrokeWidth:"0.833891"}}
                    d="M 29.371846,12.438214 C 28.69672,11.969773 27.149557,11.794108 25.939957,12.028328 25.799305,10.857226 25.15231,9.832511 24.027101,8.9249066 L 23.380105,8.4564655 22.930021,9.1298496 c -0.562605,0.8783264 -0.843908,2.1079844 -0.759516,3.2790864 0.02812,0.409886 0.168781,1.141825 0.590734,1.785931 -0.393823,0.234221 -1.2096,0.526996 -2.278549,0.526996 H 0.14452461 l -0.05626044,0.234221 c -0.19691175,1.171102 -0.19691175,4.830797 2.10976813,7.641443 1.744075,2.137262 4.3320573,3.220531 7.7358165,3.220531 7.3701232,0 12.8273902,-3.542584 15.3872422,-9.95437 1.012689,0.02928 3.178718,0 4.275798,-2.195817 0.02812,-0.05856 0.08439,-0.175665 0.281301,-0.614829 l 0.112526,-0.23422 z M 16.403805,4.1819416 h -3.094327 v 2.9277562 h 3.094327 z m 0,3.5133073 h -3.094327 v 2.9277561 h 3.094327 z m -3.656932,0 H 9.6525465 v 2.9277561 h 3.0943265 z m -3.6569313,0 H 5.995615 V 10.623005 H 9.0899417 Z M 5.4330102,11.208556 H 2.3386835 v 2.927756 h 3.0943267 z m 3.6569315,0 H 5.995615 v 2.927756 h 3.0943267 z m 3.6569313,0 H 9.6525465 v 2.927756 h 3.0943265 z m 3.656932,0 h -3.094327 v 2.927756 h 3.094327 z m 3.656931,0 H 16.96641 v 2.927756 h 3.094326 z"
                    fill="#2496ed" />
            </svg>
        </SvgIcon>
    );
}
